import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Link from "gatsby-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BetterWay from "../../images/betterWay.svg"
import "./eventPage.scss"
import "../../components/eventsTopSection/eventsTopSection.scss"
import Slider from "react-slick"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import RightDots from "../../images/top-right-dots.svg"
import LeftDots from "../../images/bottom-left-dots.svg"

// Helper function to sanitize and format the path
const sanitizePath = (name) => {
  return name
    .replace(/[<>:"\/\\|?*]/g, "_")
    .replace(/ /g, "-")
    .toLowerCase()
}

const EventPage = ({ data }) => {
  const post = data && data.contentfulEvent
  const otherEvents = data && data?.allContentfulEvent?.edges
  const url =
    (typeof window !== "undefined" && window.location.href) ||
    `https://shapepowered.com/blog/${sanitizePath(post.name)}`

  const filteredEvents = otherEvents.filter(
    (event) => sanitizePath(event?.node?.name) !== sanitizePath(post?.name)
  )

  const getSettings = (right, left) => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: right,
      prevArrow: left,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return settings
  }

  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )

  return (
    <Layout>
      <div className="eventPageWrapper">
        <SEO
          title={post.name}
          meta={[
            {
              property: "og:title",
              content: post.name,
            },
            {
              property: "og:description",
              content: post.descriptionA,
            },
            {
              property: "og:url",
              content: url,
            },
          ]}
        />
        <Container>
          <div className="eventCardWrapper">
            <div className="eventCardWrapperTop">
              <div className="dots">
                <img src={RightDots} className="rightDots" />
                <img src={LeftDots} className="leftDots" />
              </div>
              <h4>Webinar</h4>
              <h1>{post.name}</h1>
              <a href={`https://${post.eventLink}`}>Register Now</a>
            </div>
            <div className="eventCardWrapperContent">
              <p
                dangerouslySetInnerHTML={{
                  __html: post.descriptionA.childMarkdownRemark.html,
                }}
              />
              <a
                className="lightTheme paddingFix"
                href={`https://${post.eventLink}`}
              >
                Join Us!
              </a>
              <p
                className="subHeading"
                dangerouslySetInnerHTML={{
                  __html: post.descriptionB.childMarkdownRemark.html,
                }}
              />
              <img
                className="betterWayImg"
                src={BetterWay}
                alt={"A better way is possible"}
              />
              <p className="eventCardWrapperBottomLine">{post.bottomLine}</p>
              <a href={`https://${post.eventLink}`}>Register Now</a>
            </div>
          </div>
        </Container>
      </div>
      <div className="otherEventWrapper">
        <h1>Other Events</h1>
        <div className="eventsCardSectionCarouselWrapper">
          <Slider {...settings}>
            {filteredEvents?.map((card) => {
              let currEvent = card?.node
              return (
                <Link
                  to={`/events/${sanitizePath(currEvent?.name)}`}
                  className="eventsCardSectionCard"
                  key={currEvent.name}
                >
                  <div className="eventsCardSectionCardImg">
                    {currEvent.previewImg &&
                      currEvent.previewImg.gatsbyImageData && (
                        <GatsbyImage
                          image={currEvent.previewImg.gatsbyImageData}
                          title={currEvent.name}
                        />
                      )}
                  </div>
                  <div className="eventsCardSectionCardBody">
                    <h3>Webinar</h3>
                    <p className="eventsCardSectionCardBodyDate">
                      {currEvent?.eventDate}{" "}
                    </p>
                    <p className="eventsCardPreviewHeading">
                      {currEvent?.previewHeading}
                    </p>
                    <button>Get Started</button>
                  </div>
                </Link>
              )
            })}
          </Slider>
        </div>
        <div className="otherEventsCta">
          <p>Fast-track to flourishing with a custom demo.</p>
          <a href={"/demo"}>Book a Call Now</a>
        </div>
      </div>
    </Layout>
  )
}

export default EventPage

export const pageQuery = graphql`
  query EventBySlug($name: String!) {
    contentfulEvent(name: { eq: $name }) {
      name
      descriptionA {
        childMarkdownRemark {
          html
        }
      }
      descriptionB {
        childMarkdownRemark {
          html
        }
      }
      bottomLine
      isActive
      eventLink
      previewImg {
        gatsbyImageData(layout: FULL_WIDTH, breakpoints: [5750])
      }
      eventDate(formatString: "MMMM D, YYYY")
      previewHeading
    }
    allContentfulEvent(
      sort: { fields: [eventDate], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          name
          descriptionA {
            childMarkdownRemark {
              html
            }
          }
          descriptionB {
            childMarkdownRemark {
              html
            }
          }
          bottomLine
          isActive
          eventLink
          previewImg {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          eventDate(formatString: "MMMM D, YYYY")
          previewHeading
        }
      }
    }
  }
`
